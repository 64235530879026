import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import HeroBlock from '../components/Blocks/HeroBlock'
import ListenerListBlock from "../components/Blocks/ListenerListBlock"
import { getSlug } from '../helper'
import BreadcrumbSection from '../components/breadcrumb'
import PopupSchedule from "../components/popupSchedule/PopupSchedule"

const ListenerType = ({ data, location }) => {
    const { allWpTaxListenerFilterType, wpTaxListenerFilterType } = data
    const { cfListenerFilterTypes, cptListeners } = wpTaxListenerFilterType
    const listenerSlug = getSlug(cptListeners.nodes[0].uri)
    const { titleTag, metaDescription, canonicalTagOverride, robotsDirective } = cfListenerFilterTypes.typeSeoFields
    const { ogTitle, ogDescription, ogImage, ogType, twitterCard } = cfListenerFilterTypes.typeSocialOpenGraphFields
    const breadcrumbItems = [
        {
            name: 'Listeners',
            url: listenerSlug,
        },
        {
            name: `Category: ${wpTaxListenerFilterType.name}`,
        }

    ]

    const canonical = process.env.GATSBY_SITE_URL + location.pathname

    const [showSchedule, setShowSchedule] = useState(false)

    useEffect(() => {
        if (showSchedule) {
            document.querySelector('html').setAttribute('style', 'overflow:hidden')
        } else {
            document.querySelector('html').setAttribute('style', 'overflow:initial')
        }
    }, [showSchedule])

    useEffect(() => {
        if (window.location.href.includes("view-demo=1")) {
            setShowSchedule(true);
        }
    }, []);

    return (
        <Layout>
            <SEO
                title={titleTag}
                description={metaDescription}
                canonical={canonicalTagOverride || canonical}
                robotsDirective={robotsDirective}
                ogTitle={ogTitle}
                ogDescription={ogDescription}
                ogImage={ogImage}
                ogType={ogType}
                twitterCard={twitterCard}
                classBody="listener-type"
                breadcrumbItems={breadcrumbItems}
            />
            <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
            <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
            <section className="intro-category">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <HeroBlock
                                h1={cfListenerFilterTypes.headline}
                                subText={cfListenerFilterTypes.body}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className="listener-type-related">
                <div className="container">
                    <ListenerListBlock
                        headlineSearch={cfListenerFilterTypes.descriptionAboveSearch ? cfListenerFilterTypes.descriptionAboveSearch : 'Is there a Listener built for the app I use?'}
                        subHeadlineSearch={cfListenerFilterTypes.instructionsAboveSearch ? cfListenerFilterTypes.instructionsAboveSearch : 'Enter the name of an app you use.'}
                        headlineRelated={cfListenerFilterTypes.headlineRelated}
                        listenerSlug={listenerSlug}
                        relatedListener={cptListeners.nodes}
                        listenerFilterTypes={allWpTaxListenerFilterType.edges}
                    />
                </div>
            </section>
        </Layout>
    )
}

export default ListenerType
export const pageQuery = graphql`
     query PageListenerTypeTemplate($slug: String!) {
        allWpTaxListenerFilterType {
            edges {
                node {
                    slug
                    name
                    cptListeners {
                        nodes{
                            title
                        }
                    }
                }
            }
        }
        wpTaxListenerFilterType(slug: {eq: $slug}) {
            cptListeners {
                nodes {
                    cfListener {
                        mainElements {
                            listenerExcerpt
                            listenerNamePlural
                            logoFileSvg
                            useImageInsteadOfSvg
                            mainName
                            slug
                            logoFileImage {
                                ...WordpressImageFields
                            }
                            iconFileSvg
                            iconUseImageInsteadOfSvg
                            iconFileImage {
                                ...WordpressImageFields
                            }
                        }
                    }
                    uri
                }
            }
            slug
            name
            cfListenerFilterTypes {
                body
                headline
                headlineRelated
                descriptionAboveSearch
                instructionsAboveSearch
                typeSeoFields {
                    canonicalTagOverride
                    metaDescription
                    robotsDirective
                    titleTag
                }
                typeSocialOpenGraphFields {
                    ogDescription
                    ogTitle
                    ogType
                    twitterCard
                    ogImage {
                        ...WordpressImageFields
                    }
                }
            }
        }
    }
`
